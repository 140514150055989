jQuery(document).ready(function($){
    var personalToggler = $('.nav-toggler-personal');
    var sidebarToggler = $('.nav-toggler-sidebar');
    var sidebarClose = $('.nav-toggler-sidebar-close');

    personalToggler.on('click',function(e){
        e.preventDefault();

        if(!personalToggler.hasClass('active')){
            $(this).addClass('active');
        }else{
            $(this).removeClass('active');
        }
    });

    sidebarToggler.on('click',function(e){
        e.preventDefault();

        if(!sidebarToggler.hasClass('active')){
            $(this).addClass('active');
        }else{
            $(this).removeClass('active');
        }
    });

    sidebarClose.on('click',function(e){
        e.preventDefault();

        if(!sidebarToggler.hasClass('active')){
            sidebarToggler.addClass('active');
        }else{
            sidebarToggler.removeClass('active');
        }
    });
});